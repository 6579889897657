/* Fonts */
@import url('https://fonts.cdnfonts.com/css/galactic-vanguardian-ncv');

.font-sm{
  font-size: 20px !important;
}

.font-s{
  font-size: 18px !important;
}

.font-xs{
  font-size: 15px !important;
}

.font-xxs{
  font-size: 13.5px !important;
}

.font-xxxs{
  font-size: 12px !important;
}

.font-bold{
  font-weight: bold !important;
}

.text-justify{
  text-align: justify;
  text-justify: inter-word;
}

/* Login */
.alert-header {
  font-size: 20px !important
}

.alert-body {
  font-size: 15px !important
}

.nav-link {
  padding: 0.25rem 0.75rem !important;
  color: #3c763d !important;
  font-weight: 500;
}

.prefix-size{
  width: 45px !important;
}

.form-valid{
  border-color: #198754 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.form-valid:focus {
  border-color: #198754 !important;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25) !important;
}

.form-invalid{
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.form-invalid:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

.feedback-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.feedback-valid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}


/* Header */
.header {
  background: url('../public/banner.png');
  height: 18vh;
  min-height: 150px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
  border-radius: 0.3rem !important;
  padding: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.header-title {
  font-size: 26px !important;
  color: #fff !important;
  font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

/* Quiz */
.quiz {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  margin-bottom: 50px;
}

.quiz ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.quiz-question {
  font-weight: bold;
  display: block;
  margin: 0;
  padding-left: 5px;
  padding-bottom: 5px;
}

.quiz-answer {
  font-size: 16px;
  margin: 0;
  padding: 10px;
  background: #f7f7f7;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.quiz-answer:hover {
  background: #eee;
}

.quiz-answer:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background: #fff;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.quiz-answer.active:before {
  background-color: #333;
  border-color: #333;
}

.quiz-answer.correct:before {
  background-color: green;
  border-color: green;
}

.quiz-answer.incorrect:before {
  background-color: red;
  border-color: red;
}

.quiz-answer.active.correct:before {
  outline: 2px solid green;
  outline-offset: 2px;
}

.quiz-answer.active.revert:before {
  background-color: #333;
  border-color: #333;
}

.quiz-answer.correct.revert:before {
  background-color: green;
  border-color: green;
  -webkit-filter: invert(90%);
  filter: invert(90%);
}

.quiz-explication {
  background-color: #f3f3c1;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px
}

.top-right {
  position: absolute;
  right: 10px;
  top: 10px;
}

.float-right {
  position: relative;
  float: right;
  margin-bottom: 20px;
}

/* Reader */
.reader {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  padding-left: 3px;
  padding-right: 3px;
}

.line {
  margin-left: -5px;
  margin-right: -5px;
}

.reader .line > h3 {
  font-size: 1.2em;
  color: #000;
  background-color: #ddd;
  padding: 5px 10px;
  margin: 10px 0 5px;
  border-radius: 5px;

}

.reader .line .content {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 10px 0 5px;
}

.reader .line br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-bottom: 10px; /* change this to whatever height you want it */
}

.reader .line strong {
  font-weight: bold;
}

.reader .line .ms-2{
  margin-left: 20px;
  margin-right: 20px;
}

.reader .line .content {
  margin-bottom: 10px;
}

/* Footer */
.footer {
  font-style: italic;
  font-size: 12px;
}

.footer-img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 120px;
}

/* Corousel */
.carousel-control-prev{
  position: relative !important;
  display: inline !important;
  float:left;
  margin-top: 10px;

}

.carousel-control-next{
  position: relative !important;
  display: inline !important;
  float: right;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
}

/* Buttons */
.btn-header {
  font-size: 20px;
  padding: 2px 9px;
  border: 1px solid #343a40;
  border-radius: 5px;
}

.btn-msize {
  font-size: 20px !important;
  padding: 5px 7px !important;
}

.btn-up {
  bottom: 50px !important
}

.btn-download {
  width: 35px;
  height: 42px;
  font-size: 20px !important;
  padding: 5px 7px !important;
  border: 1px solid #343a40;
  border-radius: 5px;
}

/* Accordion */
.accordion-body {
  padding: 1rem 1rem !important;
}

.accordion-button {
  color: #3c763d !important;
  background-color: #dff0d8 !important;
  border-color: #d6e9c6 !important;
  display: block !important;
  padding-left: 0.8rem !important;
  padding-right: 0.6rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 1rem !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #29008a;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button::after {
  background-image: none !important
}

.accordion-header {
  font-size: 20px !important;
}

/* Padding */
.no-padding {
  padding: 0 !important;
}

.padding-right {
  padding-right: 10px !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-left {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-top: 0px !important
}

.padding-s {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-xs {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.padding-xxs {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

/* Colors */
.primary-color {
  color: #337ab7 !important;
}

.primary-background{
  background-color: #337ab7 !important;
}

.white-color {
  color: #fff !important;
}

.success-color {
  color: #3c763d !important;
}

.success-background {
  background-color: #3c763d !important;
}

.blue-color {
  color: #343a40 !important;
}

.blue-background {
  background-color: #343a40 !important;
}

.yellow-background,
.yellow-background h3,
.yellow-background p {
  background-color: #faa99d !important;
  border-radius: 5px;
}

/* Miscelanius */
.night-mode {    
  -webkit-filter: invert(90%);
  filter: invert(90%);
  background: white;
}

.hidden{
  visibility: hidden
}

.link {
  font-style: italic;
  color: #3c763d;
  text-decoration: none !important;
  cursor: pointer;
  user-select: none;
  outline: none;
}

table>tbody>tr>td {
  vertical-align: middle;
}

.icon-fa {
  width: 24%;
  display: inline-block;
}

.inline {
  display: inline;
}

.inline-block{
  display: inline-block;
}

.clickable {
  cursor: pointer !important;
}

.not-clickable {
  cursor: default !important;
}

.not-clickable .accordion-button {
  cursor: default !important;
}

@media screen and (max-width: 600px) {
  .modal-sm{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
}

.fixed-bottom-right{
  position: fixed;
  bottom: 0; 
}

.loader {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.hr{
  background-color: 1px solid rgba(0, 0, 0, 0.125);
  color: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: -10px;
}

.hr-2 {
  color: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 15px;
  margin-bottom: 3px;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.width-100{
  width: 100%;
  height: 40px;
}

.transparent{
  color: transparent;
}